<template>
  <div class="row justify-content-center align-content-center">
    <b-container class="bv-example-row justify-content-center">
      <b-row align-self="center">
        <b-col v-for="group in uniqueGroupsList(routes)" align-content="stretch" class="justify-content-center">
          <h2 class="category-header">{{ group }}</h2>
          <ul class="list-group">
            <li v-for="route in filterRoutesByGroup(group, routes)">
              <a class="list-group-item" :href="route.entrypoint">
                <a class="route-link" :href="route.entrypoint">{{ route.title }}</a>
              </a>
            </li>
          </ul>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>


<script>
import {
  ArrowUpIcon,
  VideoIcon
} from 'vue-feather-icons';
export default {
  data() {
    return {
      routes: [],
      navMode: process.env.VUE_APP_NAVIGATION_MODE,
      showAccountConfirmAlert: 'no',
      filteredGroups: [],
      filteredRoutes: [],
    }
  },
  methods: {
    filterRoutesByGroup(group, routes) {
      return routes.filter((x) => (x.group == group)).sort((a, b) => {
        if (a.entrypoint < b.entrypoint) {
          return -1;
        }
        if (a.entrypoint > b.entrypoint) {
          return 1;
        }
        return 0;
      })
    },
    uniqueGroupsList(routes) {
      let unique = [...new Set(routes.map((x) => (x.group)))];
      return unique
    },
  },
  beforeMount() {

    this.showAccountConfirmAlert = this.$cookies.get('showAccountConfirmAlert');
    this.axios({
      method: 'post',
      url: '' + this.$hostname_auth + '/auth/get_frontend_routes',
      data: { 'user_id': this.$cookies.get('id_sml') }
    }).then((res) => {
      this.routes = res.data;

    }
    )
  },
  components: {
    ArrowUpIcon,
    VideoIcon,
  }
}
</script>

<style>
.list-group-item {
  background-color: #343a40;
  color: #fff;
  border: none;
  transition: background-color 0.3s ease;
}

.list-group-item:hover {
  background-color: #495057;
}

.route-link {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.route-link:hover {
  color: #ff9800;
}

.list-group {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* padding: 20px 0; */
  margin: 20px 0;
  background-clip: content-box;
}

.category-header {
  text-align: center;
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 20px;
  background-color: #360241;
  color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  position: relative;
  overflow: hidden;
}

.category-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.05) 100%);
  transform: scale(2);
  transition: transform 0.3s ease;
}

.category-header:hover::before {
  transform: scale(1);
}

.modal-content {
  background-color: transparent;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 200px; */
  height: 40px;
  text-align: center;
  color: #fff;
  border: 2px solid transparent;
  transition: border-color 0.9s ease, transform 0.3s ease;
  box-sizing: border-box;
  padding: 0 30px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-decoration: none;
  margin-bottom: 10px;
  font-weight: bold;
}

.button:hover {
  border-color: #fff;
  /* transform: rotate(1deg) scale(1.1); */
  transform: scale(1.1);
}
</style>
